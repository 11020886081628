"use client";

import classNames from "classnames/bind";
import { useObserveElementRef } from "../../../helpers/in-viewport/in-viewport";
import { CosmosButton } from "@cosmos/web/react";
import { Kicker } from "../../kicker/kicker";
import styles from "./animated-product-teaser-card.module.css";
import { DietaryMarks } from "../../dietary-marks/dietary-marks";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";

const SCROLL_THRESHOLD = 0.5;
const cx = classNames.bind(styles);

export const AnimatedProductTeaserCard = ({
  backgroundColor,
  backgroundImageUrl,
  linkUrl,
  logoHeadingUrl,
  logoHeadingAlt,
  name,
  title,
  disclaimer,
  description,
  centerDisclaimer,
  linkText,
  animationStartImageUrl,
  animationStartImageAlt,
  animationEndImageUrl,
  animationEndImageAlt,
}: {
  backgroundColor: string | null;
  backgroundImageUrl: string | null;
  linkUrl: string | null;
  logoHeadingUrl: string | null;
  logoHeadingAlt: string | null;
  name: string | null;
  title: string | null;
  disclaimer: string | null;
  description: string | null;
  centerDisclaimer: boolean | null;
  linkText: string | null;
  animationStartImageUrl: string | null;
  animationStartImageAlt: string | null;
  animationEndImageUrl: string | null;
  animationEndImageAlt: string | null;
}) => {
  const backgroundColorStyle = backgroundColor
    ? { backgroundColor: `${backgroundColor}` }
    : null;

  const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
    threshold: SCROLL_THRESHOLD,
  });

  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        {/* todo: backgroundColor is required in the CMS so always true */}
        <a
          className={cx("card", {
            "card--has-gradient": backgroundColor,
            "card--has-background": backgroundImageUrl,
          })}
          href={linkUrl ?? undefined}
          style={backgroundColorStyle ?? undefined}
        >
          {backgroundImageUrl && (
            <div aria-hidden="true" className={cx("background-image-wrapper")}>
              <img
                loading={imageLoading}
                className={cx("background-image", {
                  "background-image--animating": hasIntersected,
                })}
                alt=""
                src={backgroundImageUrl ?? undefined}
                sizes="(width >= 1000px) min(100vw, 1222px), (width >= 650px) 600px, 100vw"
                srcSet={[320, 360, 420, 600, 650, 1222]
                  .flatMap((width) => [width * 1, width * 1.5, width * 2])
                  .map((width) => {
                    const url = getContentfulImageUrl(backgroundImageUrl, {
                      width,
                    });
                    return `${url} ${width}w`;
                  })
                  .join(", ")}
              />
              <div
                className={cx("background-image-gradient")}
                style={{
                  backgroundImage: `radial-gradient(ellipse at bottom, var(--color-transparent) 50%, ${backgroundColor})`,
                }}
              />
            </div>
          )}
          <div className={cx("text-content")}>
            {/* HACK: leave this div here to `space-between` pushes the main content area to the bottom */}
            <div>
              {logoHeadingUrl && (
                <img
                  loading={imageLoading}
                  className={cx("logo")}
                  alt={logoHeadingAlt ?? ""}
                  sizes="(width >= 1000px) 300px, (width >= 650px) 200px, (width >= 350px) 130px, 100px"
                  srcSet={[300, 200, 130, 100]
                    .flatMap((width) => [width * 1, width * 1.5, width * 2])
                    .map((width) => {
                      const url = getContentfulImageUrl(logoHeadingUrl, {
                        width,
                      });
                      return `${url} ${width}w`;
                    })
                    .join(", ")}
                />
              )}
            </div>

            <div>
              {(name || title) && (
                <div className={cx("heading-group")}>
                  {name && (
                    <span className={cx("kicker-wrapper")}>
                      <Kicker kind="bare" text={name} />
                    </span>
                  )}
                  {title && !logoHeadingUrl && (
                    <h3 className={cx("title")}>{title}</h3>
                  )}
                </div>
              )}
              <p
                className={cx("text", {
                  "text--has-disclaimer": disclaimer,
                })}
              >
                {description}
              </p>
              {disclaimer && (
                <span
                  className={cx("disclaimer", {
                    "disclaimer--centered": centerDisclaimer,
                  })}
                >
                  {disclaimer}
                </span>
              )}
              {linkUrl && linkText && (
                <div className={cx("button-wrapper")}>
                  <CosmosButton
                    decorative={true}
                    kind={
                      backgroundColor || backgroundImageUrl
                        ? "tertiary"
                        : "primary"
                    }
                    appearance={
                      backgroundColor || backgroundImageUrl ? "light" : "dark"
                    }
                    size="large"
                    className={cx("button")}
                  >
                    {linkText}
                  </CosmosButton>
                </div>
              )}
            </div>
          </div>
          {animationStartImageUrl && animationEndImageUrl && (
            <div className={cx("image-wrapper")} ref={ref}>
              <img
                loading="eager"
                sizes="(min-width: 1000px) 612px, (min-width: 650px) 600px, 300px"
                className={cx("end-image", {
                  "end-image--animating": hasIntersected,
                })}
                srcSet={[612, 600, 300]
                  .flatMap((width) => [width * 1, width * 1.5, width * 2])
                  .map((width) => {
                    const url = getContentfulImageUrl(animationEndImageUrl, {
                      width,
                    });
                    return `${url} ${width}w`;
                  })
                  .join(", ")}
                alt={animationEndImageAlt || "Teaser image of the can"}
              />
              <img
                loading="eager"
                sizes="(min-width: 1000px) 612px, (min-width: 650px) 600px, 300px"
                srcSet={[612, 600, 300]
                  .flatMap((width) => [width * 1, width * 1.5, width * 2])
                  .map((width) => {
                    const url = getContentfulImageUrl(
                      animationStartImageUrl ?? "",
                      {
                        width,
                      },
                    );
                    return `${url} ${width}w`;
                  })
                  .join(", ")}
                className={cx("start-image", {
                  "start-image--animating": hasIntersected,
                })}
                alt={animationStartImageAlt || "Teaser image of the can"}
              />
              <DietaryMarks
                kind="vegetarian"
                className={cx("dietary-mark-vegetarian")}
              />
              {/* High Sugar mark currently not implimented */}
              {/* <dietaryMarks
                kind="high-sugar"
                className={cx("dietary-mark-high-sugar")}
                sugarFree={true}
              /> */}
            </div>
          )}
        </a>
      </div>
    </div>
  );
};
